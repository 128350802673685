






























































































import { Component, Vue } from 'vue-property-decorator';
import axios from "axios";

const config = require("@/config/config.json")
const projects = config.projects

export default {
  data: function() {
    return {
      players: config.players,
      projects: config.cumulatedProjects,
      years: (new Date().getFullYear() - 2017)
    }
  },
}
